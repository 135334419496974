import React from 'react';
import clsx from 'clsx';

import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';

import { initiativebewerbung } from './initiativbewerbung.module.css';
import { Initiativbewerbung } from '@mangoart/ezagrar/modules/initiativbewerbung';

export const InitiativBewerbungsPage = ({ data, location }) => {
  return (
    <>
      <PageTitle className={'defaultWidth'}>Initiativbewerbung</PageTitle>
      <div className={clsx('defaultWidth', initiativebewerbung)}>
        <div>
          <p>
            Unsere engagierten Mitarbeiter/innen sind diejenigen, die den Unternehmenserfolg sicherstellen. Wir suchen
            laufend qualifizierte Personen, die Teil unseres Teams werden möchten. Als Großhandels- und
            Dienstleistungsunternehmen bietet die die EZAGRAR verschiedene berufliche Chancen: von Positionen im
            agrarisch/technischen Bereich bis hin zu Stellen mit kaufmännischen Aufgaben – die Möglichkeiten bei uns zu
            starten sind breit gestreut.
          </p>
          <p>
            Du bist Schulabsolvent oder suchst eine{' '}
            <strong>neue Herausforderung? Dann bewirb dich jetzt bei uns!</strong>
          </p>
        </div>
        <div>
          <Initiativbewerbung />
        </div>
      </div>
    </>
  );
};
