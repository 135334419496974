import React, { useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import clsx from 'clsx';
import {
  form,
  formControl,
  hint,
  error,
  required,
  acceptDatenschutz,
  acceptDatenschutzLabel,
} from './Initiativbewerbung.module.css';
import { FileUpload } from './FileUpload';
import { Spinner } from './Spinner';

export const Initiativbewerbung = () => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const fileNameField = useRef();

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  function encode(data) {
    const body = new FormData();

    Object.keys(data).forEach((key) => {
      if (['zeugnisse', 'motivationsschreiben', 'lebenslauf'].includes(key)) {
        for (var i = 0; i < data[key].length; i++) {
          body.append(`${key}_${i}`, data[key][i]);
        }
      } else {
        body.append(key, data[key]);
      }
    });

    return body;
  }

  const onSubmit = (data) => {
    setSubmitting(true);
    fetch('https://mailproxy.projects.localhost.at/initiativbewerbung.php', {
      method: 'POST',
      // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        mailId: 'initiativbewerbung',
        ...data,
      }),
    }).then(() => {
      setSubmitted(true);
      setSubmitting(false);
    });
  };

  if (submitted) {
    return (
      <div>
        <h2>Vielen Dank für Ihre Bewerbung!</h2>
        <p>
          Um eine detaillierte Übersicht zu bekommen und die Auswahl einzugrenzen, benötigen wir ein bisschen Zeit. Wir
          bitten daher um etwas Geduld und werden uns verlässlich in den nächsten Tagen bei dir melden.
        </p>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={form}>
        <fieldset>
          <legend>Persönliche Daten</legend>
          <div className={formControl}>
            <label>Titel vorangestellt</label>
            <select {...register('titel_vorangestellt')}>
              <option value=""></option>
              <option>DI(FH)</option>
              <option>Dipl. Ing.</option>
              <option>Dkfm.</option>
              <option>Dkfm.(FH)</option>
              <option>Dr.</option>
              <option>Ing.</option>
              <option>Mag.</option>
              <option>Mag.(FH)</option>
            </select>
          </div>
          <div className={clsx(formControl, required)}>
            <label>Vorname</label>
            <input {...register('firstname', { required: true })} />
            {errors.firstname && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>Nachname</label>
            <input {...register('lastname', { required: true })} />
            {errors.lastname && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={formControl}>
            <label>Titel nachgestellt</label>
            <select {...register('titel_nachgestellt')}>
              <option value=""></option>
              <option>BA</option>
              <option>Bakk.</option>
              <option>Bakk.phil</option>
              <option>Bakk.techn</option>
              <option>BSc</option>
              <option>LL.B.</option>
              <option>LL.M.</option>
              <option>M.B.L.</option>
              <option>M.E.S.</option>
              <option>MA</option>
              <option>MAS</option>
              <option>MBA</option>
              <option>MSc</option>
            </select>
          </div>
          <div className={clsx(formControl, required)}>
            <label>Straße / Hausnummer</label>
            <input {...register('address', { required: true })} />
            {errors.address && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>PLZ</label>
            <input {...register('plz', { required: true })} />
            {errors.plz && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>Ort</label>
            <input {...register('city', { required: true })} />
            {errors.city && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>Land</label>
            <input {...register('country', { required: true })} />
            {errors.country && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>Nationalität</label>
            <input {...register('nationality', { required: true })} />
            {errors.nationality && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>Geburtstag</label>
            <input type="date" {...register('birthday', { required: true })} />
            {errors.birthday && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>Telefon</label>
            <input {...register('phone', { required: true })} />
            {errors.phone && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
          <div className={clsx(formControl, required)}>
            <label>Email</label>
            <input {...register('email', { required: true })} />
            {errors.email && <span className={error}>Dieses Feld ist ein Pflichtfeld!</span>}
          </div>
        </fieldset>
        <fieldset>
          <legend>Daten zu Ihrer Bewerbung</legend>
          <div className={formControl}>
            <label>Früh. Eintrittsdatum</label>
            <input type="date" {...register('eintrittsdatum')} />
          </div>
          <div className={formControl}>
            <label>Kündigungsfrist</label>
            <input {...register('kuendigungsfrist')} />
          </div>
          <div className={formControl}>
            <label>
              Gehaltsvorstellung<span className={hint}>Monatsbrutto in EUR</span>
            </label>
            <input {...register('gehaltsvorstellung')} />
          </div>
          <div className={formControl}>
            <label>Von Firma erfahren durch</label>
            <input {...register('learned_by')} />
          </div>
          <div className={formControl}>
            <label>Anmerkung</label>
            <textarea {...register('anmerkung')} />
          </div>
        </fieldset>
        <fieldset>
          <legend>Dokumente</legend>
          <div>
            Hier können Sie Ihrer Bewerbung Dateien hinzufügen. Zulässig sind die Dateiformate DOC, DOCX, PDF, JPG,
            JPEG, GIF, TIFF, PNG, die maximale Größe beträgt 4 MB pro Dokument.
          </div>
          <div className={formControl}>
            <label>Lebenslauf</label>
            <FileUpload name="lebenslauf" />
          </div>
          <div className={formControl}>
            <label>Motivationsschreiben</label>
            <FileUpload name="motivationsschreiben" />
          </div>
          <div className={formControl}>
            <label>Zeugnisse/Zertifikate</label>
            <FileUpload name="zeugnisse" />
          </div>
        </fieldset>
        <fieldset>
          <legend>Datenschutz</legend>
          <label
            className={acceptDatenschutzLabel}
            for="accept_datenschutz"
            style={{ display: 'flex', gap: 16, alignItems: 'flex-start' }}
          >
            <input
              id="accept_datenschutz"
              className={acceptDatenschutz}
              type="checkbox"
              {...register('accept_datenschutz', { required: true })}
              style={{ marginTop: '0.2em', marginLeft: '1em' }}
            />
            <div>
              Ich habe die{' '}
              <a href="/datenschutz" target="_blank">
                Datenschutzbestimmungen
              </a>{' '}
              gelesen und akzeptiere Sie.
            </div>
          </label>
          {errors.accept_datenschutz && (
            <span className={error} style={{ color: 'red' }}>
              Sie müssen die Datenschutzbestimmungen akzeptieren!
            </span>
          )}
        </fieldset>
        <button disabled={submitting}>
          {submitting ? (
            <>
              Formular wird verschickt
              <Spinner />
            </>
          ) : (
            'Absenden'
          )}
        </button>
      </form>
    </FormProvider>
  );
};
