import React, { useState } from 'react';

import { fileUpload } from './FileUpload.module.css';
import { useFormContext } from 'react-hook-form';

export const FileUpload = ({ name }) => {
  const { register } = useFormContext();
  const [file, setFile] = useState();

  const fileSelected = (event) => {
    const file = event.target.files.length > 0 ? event.target.files[0] : null;
    if (file) {
      setFile(file);
    } else {
      setFile(null);
    }
  };

  return (
    <div className={fileUpload}>
      <button>Auswählen</button>
      <input type="text" value={file ? file.name : ''} disabled={true} />
      <input
        type="file"
        {...register(name)}
        onChange={fileSelected}
        accept="image/*,.pdf,.jpg,.jpeg,.gif,.tiff,.png,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </div>
  );
};
